import PropTypes from 'prop-types'
import React from 'react'
import '../../sass/login.scss'
import { withFormsy } from 'formsy-react'
import Inputmask from 'react-input-mask'
import ValidationErrorMessage from './Common/ValidationErrorMessage'

class Input extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.validateEmptyField = this.validateEmptyField.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.prepopulateFields = this.prepopulateFields.bind(this)
    this.clearPrepopulatedFields = this.clearPrepopulatedFields.bind(this)
  }

  componentDidMount () {
    return this.props.isValid
      ? this.prepopulateFields(this.props.value)
      : this.clearPrepopulatedFields()
  }

  handleChange (event) {
    const value = event.currentTarget.value
    if (this.props.errorMessage != null) {
      this.props.setValue(value)
    } else if (this.props.isValidValue(value)) {
      this.props.setValue(value)
    } else {
      this.props.setValue(value, false)
    }
    if (this.props.setName && this.props.unsetName) {
      value
        ? this.props.setName(this.props.name)
        : this.props.unsetName(this.props.name)
    }
  }

  // required is made as custom validation due to formsy-react validation change where required validators are run on every form's input on any other form input element change, and even on input's conponentDidMount
  validateEmptyField (value) {
    if (this.props.setCustomValidationError) {
      value.trim() === '' && this.props.required
        ? this.props.setCustomValidationError(
            this.props.name,
            this.props.validationErrors && this.props.validationErrors.required
          )
        : this.props.setCustomValidationError(this.props.name, '')
    }
  }

  handleBlur (event) {
    const value = event.currentTarget.value.toString().trim()

    if (this.props.onBlur) {
      this.props.setValue(value)
      // onBlur should have access to parent state, so it sets custom error itself
      this.props.onBlur(event)
    } else {
      this.props.setValue(value)
      this.validateEmptyField(value)
    }
  }

  handleKeyUp (event) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event)
    }
  }

  prepopulateFields (email) {
    if (this.props.prepopulateFields) {
      this.props.prepopulateFields(email)
    }
  }

  clearPrepopulatedFields () {
    if (this.props.clearPrepopulatedFields) {
      this.props.clearPrepopulatedFields()
    }
  }

  render () {
    if (this.props.hidden) {
      return null
    }
    let label = (
      <label
        className={`${this.props.classNameBase}__label`}
        htmlFor={this.props.name}
      >
        {this.props.label}
      </label>
    )
    // adding (optional) label to optional inputs
    /* eslint react/no-danger: 0 */
    if (this.props.classNameBase === 'enrollment' && !this.props.required) {
      label = (
        <label
          className={`${this.props.classNameBase}__label`}
          dangerouslySetInnerHTML={{ __html: this.props.label }}
          htmlFor={this.props.name}
        />
      )
    }
    let errorMessage = this.props.errorMessage
    const disabled = this.props.disabled
    const readOnly =
      this.props.name === 'dealerOwner.email'
        ? false
        : !!this.props.prepopulated
    if (this.props.customValidationError) {
      errorMessage = this.props.customValidationError
    }
    let errorMessageBlock
    let errorClassName = ''
    if (errorMessage && !disabled) {
      errorMessageBlock = <ValidationErrorMessage text={errorMessage} />
      errorClassName = 'has-errors'
    }

    return (
      <div
        className={`form-group ${errorClassName} ${this.props.classNameBase}`}
      >
        {this.props.type === 'hidden' ? null : label}
        <Inputmask
          className={`${this.props.classNameBase}__textinput form-control`}
          disabled={disabled}
          mask={this.props.format}
          maskChar={null}
          maxLength={this.props.maxLength}
          name={this.props.name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          placeholder={this.props.placeholder || ''}
          prepopulated={this.props.prepopulated}
          readOnly={readOnly}
          type={this.props.type || 'text'}
          value={this.props.prepopulated || this.props.value}
        />
        <div data={this.props.name}>{errorMessageBlock}</div>
      </div>
    )
  }
}

Input.propTypes = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classNameBase: PropTypes.string,
  clearPrepopulatedFields: PropTypes.func,
  customValidationError: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: PropTypes.object,
  format: PropTypes.string,
  hidden: PropTypes.bool,
  isValid: PropTypes.bool,
  isValidValue: PropTypes.func,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  prepopulated: PropTypes.object,
  prepopulateFields: PropTypes.func,
  required: PropTypes.bool,
  setCustomValidationError: PropTypes.func,
  setName: PropTypes.func,
  setValue: PropTypes.func,
  skipCustomBlurValidation: PropTypes.bool,
  type: PropTypes.string,
  unsetName: PropTypes.func,
  validationErrors: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
}

export default withFormsy(Input)
export { Input as PureInput }
